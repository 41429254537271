import { type GeoLocation, geoLocationSchema } from "@src/appV2/Location/types";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

/**
 * Get and set the geolocation from the url params
 * in the format `ll=latitude,longitude`.
 */
export function useGeoLocationFromUrlParams() {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const latLng = searchParams.get("ll");
  const [latitude, longitude] = latLng?.split(",").map(Number) ?? [];
  const parsed = geoLocationSchema.safeParse({ latitude, longitude });

  const geoLocation = parsed.success ? parsed.data : undefined;

  const onChangeGeoLocation = useCallback(
    (geoLocation: GeoLocation) => {
      history.push({
        pathname: location.pathname,
        search: `?ll=${geoLocation.latitude},${geoLocation.longitude}`,
      });
    },
    [history, location.pathname]
  );

  return { geoLocation, onChangeGeoLocation };
}
