import { MapMarkerBase } from "./MapMarkerBase";

interface MapMarkerActiveProps {
  onClick?: () => void;
}

export function MapMarkerActive({ onClick }: MapMarkerActiveProps) {
  return (
    <MapMarkerBase
      outerColor={(theme) => String(theme.palette.primary.main)}
      innerColor={(theme) => String(theme.palette.background.tertiary)}
      onClick={onClick}
    />
  );
}
