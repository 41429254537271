import { Text } from "@clipboard-health/ui-react";
// This svg is not an icon and illustration so to not introduce a new asset type, we're using SvgIcon here
// eslint-disable-next-line no-restricted-imports
import { ButtonBase, SvgIcon } from "@mui/material";

interface MapMarkerCounterProps {
  count: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function MapMarkerCounter(props: MapMarkerCounterProps) {
  const { count, onClick } = props;
  return (
    <ButtonBase onClick={onClick}>
      <SvgIcon
        sx={(theme) => ({
          width: theme.size?.iconButton.small.size,
          height: theme.size?.iconButton.small.size,
          color: theme.palette.primary.main,

          // Marker's border
          "& > path:first-of-type": {
            stroke: theme.palette.border?.darkInvert,
          },
        })}
      >
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.6692 23.5977L11.6688 23.5974C11.4934 23.4772 9.24145 21.9067 7.03646 19.3698C4.82695 16.8278 2.69996 13.3562 2.6999 9.42883C2.70274 7.01033 3.68216 4.69057 5.42508 2.97876C7.16815 1.2668 9.53256 0.302836 11.9999 0.3C14.4672 0.302836 16.8317 1.2668 18.5747 2.97876C20.3177 4.69062 21.2971 7.01045 21.2999 9.42901C21.2998 13.3563 19.1728 16.8278 16.9633 19.3698C14.7584 21.9067 12.5064 23.4772 12.331 23.5974L12.3306 23.5977C12.2342 23.6641 12.1187 23.7 11.9999 23.7C11.8811 23.7 11.7656 23.6641 11.6692 23.5977ZM14.1039 6.32707C13.4807 5.91805 12.7485 5.70006 11.9999 5.70006C10.9962 5.70006 10.0326 6.09161 9.32124 6.79024C8.60973 7.48906 8.20899 8.43802 8.20899 9.42867C8.20899 10.1673 8.43204 10.8889 8.84928 11.5022C9.26647 12.1155 9.85886 12.5926 10.5509 12.8742C11.243 13.1557 12.0042 13.2293 12.7385 13.0858C13.4728 12.9424 14.1481 12.5881 14.6786 12.0671C15.2091 11.546 15.5711 10.8814 15.7178 10.1571C15.8645 9.43275 15.7891 8.682 15.5015 8.00002C15.2139 7.3181 14.7273 6.73613 14.1039 6.32707Z"
            stroke="currentColor"
            strokeWidth="0.6"
          />
          <path
            d="M5.59281 3.27877C7.29234 1.60958 9.59661 0.670647 12.0001 0.667969C14.4036 0.670647 16.7079 1.60958 18.4074 3.27877C20.1069 4.94796 21.0629 7.21111 21.0656 9.5717C21.0656 17.1905 12.8242 22.9445 12.4729 23.1853C12.3344 23.2806 12.1693 23.3318 12.0001 23.3318C11.8309 23.3318 11.6658 23.2806 11.5272 23.1853C11.176 22.9445 2.93457 17.1905 2.93457 9.5717C2.9373 7.21111 3.89329 4.94796 5.59281 3.27877Z"
            fill="currentColor"
          />
        </svg>
      </SvgIcon>

      <Text
        semibold
        variant="body2"
        color={(theme) => theme.palette.background.tertiary}
        sx={{
          textWrap: "nowrap",
          zIndex: 1,
          position: "absolute",
          top: "50%",
          left: "50%",
          // Move the counter up a bit to account for the marker's down arrow
          transform: "translate(-50%, -60%)",
        }}
      >
        {count > 99 ? "99+" : count}
      </Text>
    </ButtonBase>
  );
}
