import { IconButton } from "@clipboard-health/ui-components";
import { Box, Stack } from "@mui/material";
import { type ReactNode } from "react";

import { ButtonInternalLink } from "../../components/ButtonInternalLink";

interface ShowcaseWrapperProps {
  onClose: () => void;
  children: ReactNode;
}

export function ShowcaseWrapper(props: ShowcaseWrapperProps) {
  const { onClose, children } = props;

  return (
    <Stack spacing={7} justifyContent="center" alignItems="center">
      <Box sx={{ position: "absolute", top: 20, right: 16 }}>
        <IconButton
          iconType="close"
          variant="outlined"
          size="small"
          LinkComponent={ButtonInternalLink}
          onClick={onClose}
        />
      </Box>
      {children}
    </Stack>
  );
}
