import { getTheme } from "@clipboard-health/ui-theme";
import { ThemeProvider } from "@mui/material";
import { useLocalStorage } from "@src/appV2/lib/utils";
import { useEffect } from "react";
import Joyride from "react-joyride";

import { PLACEMENT_PATHS } from "../Placements/paths";
import { HiringShowcase } from "./components/HiringShowcase";

interface NavbarShowcaseProps {
  isVisible: boolean;
  currentLocation: string;
}

export function NavbarShowcase(props: NavbarShowcaseProps) {
  const { isVisible, currentLocation } = props;
  const theme = getTheme();

  const [completedShowcase, setCompletedShowcase] = useLocalStorage(
    "completed-navbar-showcase",
    false
  );

  function handleClose() {
    setCompletedShowcase(true);
  }

  useEffect(() => {
    if (currentLocation === PLACEMENT_PATHS.ROOT) {
      setCompletedShowcase(true);
    }
  }, [currentLocation, setCompletedShowcase]);

  const steps = [
    {
      target: "#hiring",
      content: (
        <HiringShowcase
          onClose={() => {
            handleClose();
          }}
        />
      ),
      disableBeacon: true,
      spotlightPadding: 16,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Joyride
        spotlightClicks
        hideCloseButton
        disableCloseOnEsc
        disableOverlayClose
        steps={steps}
        run={isVisible && !completedShowcase}
        continuous={false}
        showSkipButton={false}
        floaterProps={{
          disableAnimation: true,
          hideArrow: true,
        }}
        styles={{
          tooltip: {
            padding: `${theme.spacing(10)} ${theme.spacing(9)}`,
            borderRadius: theme.spacing(8),
            width: "calc(100% - 20px)",
          },
          buttonNext: {
            display: "none",
          },
          spotlight: {
            borderRadius: theme.spacing(3),
          },
        }}
      />
    </ThemeProvider>
  );
}
