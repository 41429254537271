import { CbhIcon } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import pluralize from "pluralize";

import { MapMarkerBase } from "./MapMarkerBase";

interface MapMarkerExpandedProps {
  count: number;
  label: string;
  rating?: string | number;
  onClick?: () => void;
}

export function MapMarkerExpanded(props: MapMarkerExpandedProps) {
  const { rating, count, onClick, label } = props;

  return (
    <MapMarkerBase
      outerColor={(theme) => String(theme.palette.primary.main)}
      innerColor={(theme) => String(theme.palette.background.tertiary)}
      outerSx={(theme) => ({
        width: "fit-content",
        height: "fit-content",
        padding: isDefined(rating) ? theme.spacing(1, 1, 1, 4) : theme.spacing(2, 4),
      })}
      onClick={onClick}
    >
      <Stack direction="row" spacing={3} alignItems="center">
        <Text
          semibold
          variant="caption"
          color={(theme) => theme.palette.text.invertPrimary}
          sx={{ textWrap: "nowrap" }}
        >
          {count > 99 ? "99+" : count} {pluralize(label, count)}
        </Text>

        {isDefined(rating) && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={(theme) => ({
              backgroundColor: theme.palette.background.tertiary,
              borderRadius: theme.borderRadius?.xLarge,
              padding: theme.spacing("2px", 3),

              // CbhIcon doesn't have a size option corresponding to the designs.
              "&>svg": {
                width: theme.spacing(4),
                height: theme.spacing(4),
              },
            })}
          >
            <CbhIcon type="star-filled" />
            <Text semibold variant="caption">
              {rating}
            </Text>
          </Stack>
        )}
      </Stack>
    </MapMarkerBase>
  );
}
