import { Button, Illustration } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { ButtonInternalLink } from "../../components/ButtonInternalLink";
import { PLACEMENT_PATHS } from "../../Placements/paths";
import { ShowcaseWrapper } from "./ShowcaseWrapper";

interface HiringShowcaseProps {
  onClose: () => void;
}

export function HiringShowcase(props: HiringShowcaseProps) {
  const { onClose } = props;
  const worker = useDefinedWorker();

  useLogEffect(
    APP_V2_USER_EVENTS.SHOWCASE_VIEWED,
    {
      workerId: worker.userId,
      showcaseType: "HIRING",
    },
    {
      enabled: isDefined(worker.userId),
    }
  );

  return (
    <ShowcaseWrapper
      onClose={() => {
        logEvent(APP_V2_USER_EVENTS.SHOWCASE_DISMISSED, {
          workerId: worker.userId,
          showcaseType: "HIRING",
        });
        onClose();
      }}
    >
      <Illustration type="new-jobs" />
      <Text variant="h4" align="center">
        A new dedicated tab to look for your next job
      </Text>
      <Button
        variant="contained"
        LinkComponent={ButtonInternalLink}
        href={PLACEMENT_PATHS.ROOT}
        onClick={() => {
          logEvent(APP_V2_USER_EVENTS.SHOWCASE_CTA_CLICKED, {
            workerId: worker.userId,
            showcaseType: "HIRING",
            ctaType: "VIEW_JOBS",
          });
          onClose();
        }}
      >
        View jobs
      </Button>
    </ShowcaseWrapper>
  );
}
