import { CbhIcon } from "@clipboard-health/ui-components";
import { BaseLink } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { Badge, BottomNavigation, BottomNavigationAction, ThemeProvider } from "@mui/material";
import { DeprecatedGlobalAppV1Paths, RootPaths } from "@src/appV2/App/paths";
import { useIsJobsEnabled } from "@src/appV2/redesign/PlacementCandidate/useIsJobsEnabled";
import { SHIFT_DISCOVERY_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { type ReactElement } from "react";
import { useLocation } from "react-router-dom";

import { useGetChatChannels } from "../Facilities/Chat/api/useGetChatChannels";
import { NavbarShowcase } from "./NavbarShowcase/NavbarShowcase";
import { PLACEMENT_PATHS } from "./Placements/paths";
import { useIsShiftDiscoveryEnabled } from "./ShiftDiscovery/useIsShiftDiscoveryEnabled";

export function Navbar(): ReactElement {
  const location = useLocation();

  const isJobsEnabled = useIsJobsEnabled();
  const isShiftDiscoveryEnabled = useIsShiftDiscoveryEnabled();

  const theme = getTheme();
  const { data: chatChannelsData } = useGetChatChannels({
    refetchInterval: (data) => {
      /* We need to wait until the SDK is initialized, but SendBird does not provide
       * any out-of-the-box way to wait and perform operations after initialization. Hence
       * we're using an immediate refetching as a workaround which solves the issue. We're
       * using the lowest non-zero interval to refetch which is 1 ms. We're using this to
       * show the badge for unread chat messages in the navbar. Note that if we swap this
       * out with 'retries' instead, for some reason the SDK never initializes.
       */
      if (!data?.isSdkInitialized) {
        return 1;
      }

      return 0;
    },
  });

  const unreadChatsCount = (chatChannelsData?.channels ?? [])
    .map((channel) => channel.unreadMessageCount)
    .reduce((accumulator, count) => accumulator + count, 0);

  const hasUnreadChats = unreadChatsCount > 0;

  const shiftsPath = isShiftDiscoveryEnabled
    ? `${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_PATH}`
    : DeprecatedGlobalAppV1Paths.OPEN_SHIFTS;

  let currentLocation = location.pathname;

  // make sure we include all nested paths for shift discovery so navigation is properly selected
  // example: /shift-discovery/list and /shift-discovery/map should both be treated as /shift-discovery
  // TODO: This needs a rework so it works for all other root paths like account, etc
  if (currentLocation.startsWith(shiftsPath)) {
    currentLocation = shiftsPath;
  }

  // same for placements
  if (currentLocation.startsWith(PLACEMENT_PATHS.ROOT)) {
    currentLocation = PLACEMENT_PATHS.ROOT;
  }

  return (
    <ThemeProvider theme={theme}>
      <NavbarShowcase
        isVisible={isShiftDiscoveryEnabled && currentLocation === shiftsPath && isJobsEnabled}
        currentLocation={currentLocation}
      />
      <BottomNavigation showLabels value={currentLocation}>
        <BottomNavigationAction
          component={BaseLink}
          to={shiftsPath}
          value={shiftsPath}
          icon={
            <CbhIcon
              type={currentLocation === shiftsPath ? "search-colored" : "search"}
              size="large"
            />
          }
          label="Shifts"
          id="shifts"
        />
        {isJobsEnabled && (
          <BottomNavigationAction
            component={BaseLink}
            to={PLACEMENT_PATHS.ROOT}
            value={PLACEMENT_PATHS.ROOT}
            icon={
              <CbhIcon
                type={currentLocation === PLACEMENT_PATHS.ROOT ? "briefcase-colored" : "briefcase"}
                size="large"
              />
            }
            label="Hiring"
            id="hiring"
          />
        )}
        <BottomNavigationAction
          component={BaseLink}
          to={DeprecatedGlobalAppV1Paths.MY_SHIFTS}
          value={DeprecatedGlobalAppV1Paths.MY_SHIFTS}
          icon={
            <CbhIcon
              type={
                currentLocation === DeprecatedGlobalAppV1Paths.MY_SHIFTS
                  ? "clipboard-with-notes-colored"
                  : "clipboard-with-notes"
              }
              size="large"
            />
          }
          label="Bookings"
          id="bookings"
        />
        <BottomNavigationAction
          component={BaseLink}
          to={DeprecatedGlobalAppV1Paths.CHAT_CHANNELS_LIST}
          value={DeprecatedGlobalAppV1Paths.CHAT_CHANNELS_LIST}
          icon={
            <Badge variant="dot" invisible={!hasUnreadChats} color="info">
              <CbhIcon
                type={
                  currentLocation === DeprecatedGlobalAppV1Paths.CHAT_CHANNELS_LIST
                    ? "chat-colored"
                    : "chat"
                }
                size="large"
              />
            </Badge>
          }
          label="Chat"
          id="chat"
        />
        <BottomNavigationAction
          component={BaseLink}
          to={DeprecatedGlobalAppV1Paths.ACCOUNT}
          value={DeprecatedGlobalAppV1Paths.ACCOUNT}
          icon={
            <CbhIcon
              type={
                currentLocation === DeprecatedGlobalAppV1Paths.ACCOUNT ? "user-colored" : "user"
              }
              size="large"
            />
          }
          label="Account"
          id="account"
        />
      </BottomNavigation>
    </ThemeProvider>
  );
}
